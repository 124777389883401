html{
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100%;
}
