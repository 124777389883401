

@media print {
    .headerMenu {
        display:none;
    }
    .printButton {
        display:none;
    }
    body { box-sizing: border-box; height: 11in; margin: 0 auto; overflow: hidden; padding: 0in 0in 0in 0in; width: 8.5in; }
    /* padding is Top Right Bottom Left */
    /* body { box-sizing: border-box; height: 9.9in; margin: 0 auto; overflow: hidden; padding: 1in 0.5in 1in 0.5in; width: 7.65in; } */
}
@media not print {
    .worksheetPrintHeader {
        display:none;
    }
    .printButtonPlaceholder {
        display:none;
    }
}

